<template>
  <div>
    <v-dialog :value="modal" scrollable persistent max-width="800px">
      <v-card>
        <v-card-title class="headline indigo darken-4 white--text justify-space-between" primary-title
          >Datos del Permisionario
          <v-btn class="float-right" icon dark @click="$emit('handleClose', 'OK')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 400px">
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  :value="dataModal.name"
                  label="Nombre"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  :value="dataModal.nit + '-' + dataModal.cod_nit"
                  label="Nit"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  :value="
                    dataModal.type_of_company == 'UNO'
                      ? 'NATURAL'
                      : dataModal.type_of_company == 'DOS'
                      ? 'JURIDICA'
                      : ''
                  "
                  label="Tipo de Compañía"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  :value="dataModal.legal_representative"
                  label="Representante legal"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  :value="dataModal.id_type"
                  label="Tipo de identificación"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  :value="dataModal.identification_number"
                  label="Número de identificación"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  :value="dataModal.address"
                  label="dirección"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  :value="dataModal.email"
                  label="Email"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  :value="dataModal.phone"
                  label="Telefono"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="4" v-if="dataModal.type == 'art'">
                <v-text-field
                  :value="dataModal.fishing_card_number"
                  label="Número de carné de pesca"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="4" v-if="dataModal.type == 'art'">
                <v-text-field
                  :value="
                    dataModal.certifying_organization != null
                      ? dataModal.certifying_organization.abbr_name
                      : ''
                  "
                  label="Organización cooperativa"
                  readonly
                ></v-text-field>
              </v-col>

              <v-flex xs12>
                <v-subheader class="title font-weight-bold"
                  >Permiso de Pesca</v-subheader
                >
              </v-flex>

              <v-col cols="3">
                <v-text-field
                  :value="
                    permission != undefined
                      ? permission.authorization_number
                      : ''
                  "
                  label="Número de Autorización"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  :value="
                    permission != undefined ? permission.date_of_issue : ''
                  "
                  label="Fecha de emisión"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  :value="
                    permission != undefined
                      ? permission.date_of_notification
                      : ''
                  "
                  label="Fecha de notificación"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  :value="
                    permission != undefined
                      ? permission.expedido_por != null
                        ? permission.expedido_por.nombre
                        : ''
                      : ''
                  "
                  label="Expedido por"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  :value="
                    permission != undefined
                      ? permission.titular_autorizacion != null
                        ? permission.titular_autorizacion.nombre
                        : ''
                      : ''
                  "
                  label="Titular de la autorización"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  :value="
                    permission != undefined
                      ? permission.effective_activation_date
                      : ''
                  "
                  label="Fecha inicio autorización"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  :value="
                    permission != undefined
                      ? permission.effective_inactivation_date
                      : ''
                  "
                  label="Fecha fin autorización"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  :value="
                    permission != undefined
                      ? permission.duration_of_permit_granted == 'UNO'
                        ? 1
                        : permission.duration_of_permit_granted == 'DOS'
                        ? 2
                        : permission.duration_of_permit_granted == 'TRES'
                        ? 3
                        : permission.duration_of_permit_granted == 'CUATRO'
                        ? 4
                        : permission.duration_of_permit_granted == 'CINCO'
                        ? 5
                        : ''
                      : ''
                  "
                  label="Vigencia del Permiso (años)"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  :value="
                    permission != undefined
                      ? permission.zona_autorizada != null
                        ? permission.zona_autorizada.nombre
                        : ''
                      : ''
                  "
                  label="Zona autorizada"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  :value="
                    permission != undefined
                      ? permission.puerto_desembarque_artesanal != null
                        ? permission.puerto_desembarque_artesanal.nombre
                        : ''
                      : ''
                  "
                  label="Puerto autorizado"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-list>
                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>Tipo de pesquera</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="item in permission != undefined
                        ? permission.type_fishery != null
                          ? permission.type_fishery
                          : array_e
                        : array_e"
                      :key="item.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="
                            item.pivot != undefined
                              ? item.pivot.description
                              : item.nombre
                          "
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-col>
              <v-col cols="6">
                <v-list>
                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title
                          >Especies Autorizadas</v-list-item-title
                        >
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="item in permission != undefined
                        ? permission.especies_autorizadas != null
                          ? permission.especies_autorizadas
                          : array_e
                        : array_e"
                      :key="item.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.nombre"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-col>

              <v-col cols="6">
                <v-list>
                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>Artes de Pesca</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="item in permission != undefined
                        ? permission.fishing_arts != null
                          ? permission.fishing_arts
                          : array_e
                        : array_e"
                      :key="item.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="
                            item.pivot != undefined
                              ? item.pivot.description
                              : item.nombre
                          "
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-col>

              <v-col cols="6">
                <v-list>
                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>Artes Autorizadas</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list-item
                      v-for="item in permission != undefined
                        ? permission.authorized_arts != null
                          ? permission.authorized_arts.split(',')
                          : array_e
                        : array_e"
                      :key="item.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="
                            items_select_authorized_arts.find(
                              (x) => x.abbr == item
                            ) != undefined
                              ? items_select_authorized_arts.find(
                                  (x) => x.abbr == item
                                ).state
                              : ''
                          "
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-col>

              <v-flex xs12>
                <v-subheader class="title font-weight-bold"
                  >Barcos Asociados</v-subheader
                >
              </v-flex>

              <v-col>
                <v-expansion-panels>
                  <v-expansion-panel
                    :key="barco.registration_number"
                    v-for="barco in dataModal.boats"
                  >
                    <v-expansion-panel-header v-slot="{}">
                      <v-row no-gutters>
                        <v-col cols="4"
                          >{{ barco.boat_name }} |
                          {{ barco.registration_number }}</v-col
                        >
                        <v-col cols="3">
                          <v-icon>fa-ship</v-icon>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row no-gutters>
                        <v-col cols="3">
                          Número de registro
                          <br />
                          <div v-if="barco.registration_number">
                            {{ barco.registration_number }}
                          </div>
                          <div v-else>N/A</div>
                        </v-col>

                        <v-col cols="3">
                          Puerto de registro
                          <br />

                          <div v-if="barco.registration_port">
                            {{
                              concepto.items_select_registration_port.find(
                                (x) => x.abbr == barco.registration_port
                              ) != undefined
                                ? concepto.items_select_registration_port.find(
                                    (x) => x.abbr == barco.registration_port
                                  ).state
                                : barco.registration_port
                            }}
                          </div>
                          <div v-else>N/A</div>
                        </v-col>

                        <v-col cols="3">
                          Tipo de barco
                          <br />

                          <div v-if="barco.boat_type">
                            {{ barco.boat_type.nombre }}
                          </div>
                          <div v-else>N/A</div>
                        </v-col>

                        <v-col cols="3">
                          Estado del pabellón
                          <br />

                          <div v-if="barco.current_state_pavilion != null">
                            {{
                              concepto.items_select_current_state_pavilion.find(
                                (x) => x.abbr == barco.current_state_pavilion
                              ) != undefined
                                ? concepto.items_select_current_state_pavilion.find(
                                    (x) =>
                                      x.abbr == barco.current_state_pavilion
                                  ).state
                                : ""
                            }}
                          </div>
                          <div v-else>N/A</div>
                        </v-col>

                        <v-col cols="3">
                          Propietario
                          <br />

                          <div v-if="barco.beneficial_owner_name">
                            {{ barco.beneficial_owner_name }}
                          </div>
                          <div v-else>N/A</div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Dimensiones</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.length_overall"
                            readonly
                            prepend-inner-icon
                            placeholder="ESLORA - Longitud total (LOA)(m)"
                            hint="ESLORA - Longitud total (LOA)(m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.calado"
                            readonly
                            prepend-inner-icon
                            placeholder="Calado / Proyecto (m)"
                            hint="Calado / Proyecto (m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.beam_extreme_breadth"
                            readonly
                            prepend-inner-icon
                            placeholder="Manga / extrema amplitud (m)"
                            hint="Manga / extrema amplitud (m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.net_registered_tonnage"
                            readonly
                            prepend-inner-icon
                            hint="Tonelaje de Registro Neto (TRN)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.gross_registered_tonnage"
                            prepend-inner-icon
                            readonly
                            hint="Tonelaje de Registro Bruto (TRB)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" v-if="barco.type_boat == 'ART'">
                          <v-text-field
                            :value="
                              barco.type_of_propulsion != null
                                ? barco.type_of_propulsion.nombre
                                : ''
                            "
                            readonly
                            hint="Tipo de propulsión."
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.main_engines_power"
                            readonly
                            prepend-inner-icon
                            placeholder="Potencia del motor principal/s"
                            hint="Potencia del motor principal/s"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.power_unit_state || barco.power_unit"
                            readonly
                            hint="Unidad de Potencia"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" v-show="title != 'CT'">
                          <v-text-field
                            :value="
                              barco.hull_material_state ||
                              (barco.hull_material || {}).nombre
                            "
                            readonly
                            hint="Material casco"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" v-show="title == 'IT'">
                          <v-text-field
                            :value="barco.warehouses_number"
                            readonly
                            prepend-inner-icon
                            placeholder="Numero de bodegas"
                            hint="Numero de bodegas"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.fish_hold_high"
                            readonly
                            prepend-inner-icon
                            placeholder="Alto de la bodega de pescado (m)"
                            hint="Alto de la bodega de pescado (m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.fish_hold_long"
                            readonly
                            prepend-inner-icon
                            placeholder="Largo de la bodega de pescado (m)"
                            hint="Largo de la bodega de pescado (m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.fish_hold_width"
                            readonly
                            placeholder="Ancho de la bodega de pescado (m)"
                            hint="Ancho de la bodega de pescado (m)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.fish_hold_capacity"
                            readonly
                            placeholder="Capacidad de la bodega de pescado (m³)"
                            hint="Capacidad de la bodega de pescado (m³)"
                            persistent-hint
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="
                              barco.fish_hold_type_state ||
                              (barco.fish_hold_type || {}).nombre
                            "
                            readonly
                            hint="Tipo de conservación del pescado"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3"></v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Construcción</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            :value="barco.year_of_construction"
                            hint="Año de construcción"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            :value="barco.construction_country"
                            hint="País de construcción"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Patente de Pesca</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.date_expedition_patent"
                            hint="Fecha de inicio de vigencia de la patente"
                            persistent-hint
                            readonly
                            number
                          ></v-text-field>
                        </v-col>

                        <v-col cols="3">
                          <v-text-field
                            :value="barco.date_expiration_patent"
                            hint="Fecha de vencimiento de la patente"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.number_of_crew"
                            hint="Numero de tripulantes"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            :value="barco.number_of_fishermens"
                            hint="Numero de pescadores"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-divider></v-divider>
                          <v-subheader class="font-weight-bold"
                            >Artes de Pesca</v-subheader
                          >
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="6">
                          <v-list>
                            <v-list-group>
                              <template v-slot:activator>
                                <v-list-item-content>
                                  <v-list-item-title
                                    >Artes de Pesca</v-list-item-title
                                  >
                                </v-list-item-content>
                              </template>
                              <v-list-item
                                v-for="item in barco.fishing_arts != undefined
                                  ? barco.fishing_arts.length > 0
                                    ? barco.fishing_arts
                                    : []
                                  : []"
                                :key="item.id"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="item.pivot.description"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-group>
                          </v-list>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            :value="barco.fishing_arts_value"
                            hint="Valor del arte de pesca ($)"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            :value="barco.fishing_zone"
                            hint="Área de operaciones"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-show="
                            barco.fishing_arts.find((x) => x.codigo == 'DOS') !=
                            undefined
                          "
                        >
                          <v-subheader
                            light
                            class="subtitle-1 font-italic font-weight-bold"
                          >
                            {{
                              barco.fishing_arts.find(
                                (x) => x.codigo == "UNO"
                              ) != undefined
                                ? barco.fishing_arts.find(
                                    (x) => x.codigo == "UNO"
                                  ).nombre
                                : ""
                            }}
                          </v-subheader>
                          <v-divider light></v-divider>
                        </v-col>
                        <!-- <div v-if="this.select_fishing_port.abbr === 'UNO'"> -->
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                            undefined
                          "
                        >
                          <v-text-field
                            :value="
                              barco.fishing_line_state != null
                                ? barco.fishing_line_state
                                : concepto.items_select_type_line.find(
                                    (x) => x.abbr == barco.fishing_line
                                  ) != undefined
                                ? concepto.items_select_type_line.find(
                                    (x) => x.abbr == barco.fishing_line
                                  ).state
                                : ''
                            "
                            hint="Denominación del arte de pesca tipo Sedal ó de anzuelo"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                            undefined
                          "
                        >
                          <v-text-field
                            :value="
                              barco.hook_type_state != null
                                ? barco.hook_type_state
                                : concepto.items_select_type_hook.find(
                                    (x) => x.abbr == barco.hook_type
                                  ) != undefined
                                ? concepto.items_select_type_hook.find(
                                    (x) => x.abbr == barco.hook_type
                                  ).state
                                : ''
                            "
                            hint="Tipo de anzuelo"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                            undefined
                          "
                        >
                          <v-text-field
                            :value="barco.hook_size"
                            hint="Tamaño de anzuelo"
                            persistent-hint
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                            undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="barco.hooks_number"
                            hint="Cantidad de anzuelos"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                            undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="barco.mother_line_length"
                            hint="Longitud de la línea madre medida en brazas"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                            undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              barco.mother_line_material_state != null
                                ? barco.mother_line_material_state
                                : concepto.items_select_mother_line_material.find(
                                    (x) => x.abbr == barco.mother_line_material
                                  ) != undefined
                                ? concepto.items_select_mother_line_material.find(
                                    (x) => x.abbr == barco.mother_line_material
                                  ).state
                                : ''
                            "
                            hint="Material de la línea madre"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                            undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              barco.downpipe_material_state != null
                                ? barco.mother_line_material_state
                                : concepto.items_select_downspout_material.find(
                                    (x) => x.abbr == barco.downpipe_material
                                  ) != undefined
                                ? concepto.items_select_downspout_material.find(
                                    (x) => x.abbr == barco.downpipe_material
                                  ).state
                                : ''
                            "
                            hint="Material de las bajantes"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            barco.fishing_arts.find((x) => x.codigo == 'UNO') !=
                            undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="barco.total_lines_number"
                            hint="Cantidad total de líneas"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-show="
                            barco.fishing_arts.find((x) => x.codigo == 'DOS') !=
                            undefined
                          "
                        >
                          <v-subheader
                            light
                            class="subtitle-1 font-italic font-weight-bold"
                          >
                            {{
                              barco.fishing_arts.find(
                                (x) => x.codigo == "DOS"
                              ) != undefined
                                ? barco.fishing_arts.find(
                                    (x) => x.codigo == "DOS"
                                  ).nombre
                                : ""
                            }}
                          </v-subheader>
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            barco.fishing_arts.find((x) => x.codigo == 'DOS') !=
                            undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              barco.art_type_traps_state != null
                                ? barco.art_type_traps_state
                                : concepto.items_select_denomination_art_fishing.find(
                                    (x) => x.abbr == barco.art_type_traps
                                  ) != undefined
                                ? concepto.items_select_denomination_art_fishing.find(
                                    (x) => x.abbr == barco.art_type_traps
                                  ).state
                                : ''
                            "
                            hint="Denominación del arte de pesca tipo trampas o nasas"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            barco.fishing_arts.find((x) => x.codigo == 'DOS') !=
                            undefined
                          "
                        >
                          <v-text-field
                            readonly
                            v-model="barco.type_traps_number"
                            hint="Cantidad de trampas o nasas"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            barco.fishing_arts.find((x) => x.codigo == 'DOS') !=
                            undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              barco.type_traps_material_state != null
                                ? barco.type_traps_material_state
                                : concepto.items_select_main_trap_material.find(
                                    (x) => x.abbr == barco.type_traps_material
                                  ) != undefined
                                ? concepto.items_select_main_trap_material.find(
                                    (x) => x.abbr == barco.type_traps_material
                                  ).state
                                : ''
                            "
                            hint="Material principal de la trampa o nasa"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="3"
                          v-show="
                            barco.fishing_arts.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-subheader
                            light
                            class="subtitle-1 font-italic font-weight-bold"
                          >
                            {{
                              barco.fishing_arts.find(
                                (x) => x.codigo == "TRES"
                              ) != undefined
                                ? barco.fishing_arts.find(
                                    (x) => x.codigo == "TRES"
                                  ).nombre
                                : ""
                            }}
                          </v-subheader>
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            barco.fishing_arts.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              barco.type_wound_artifacts_state != null
                                ? barco.type_wound_artifacts_state
                                : concepto.items_select_type_artifact.find(
                                    (x) => x.abbr == barco.type_wound_artifacts
                                  ) != undefined
                                ? concepto.items_select_type_artifact.find(
                                    (x) => x.abbr == barco.type_wound_artifacts
                                  ).state
                                : ''
                            "
                            hint="Tipo de artefactos de herir o aferrar"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            barco.fishing_arts.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            v-model="barco.amount_artifacts"
                            label="Cantidad de artefactos"
                            hint="Cantidad de artefactos"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="3"
                          sm="12"
                          md="3"
                          v-show="
                            barco.fishing_arts.find(
                              (x) => x.codigo == 'TRES'
                            ) != undefined
                          "
                        >
                          <v-text-field
                            readonly
                            :value="
                              barco.artifacts_material_state != null
                                ? barco.artifacts_material_state
                                : concepto.items_select_artifact_material.find(
                                    (x) => x.abbr == barco.artifacts_material
                                  ) != undefined
                                ? concepto.items_select_artifact_material.find(
                                    (x) => x.abbr == barco.artifacts_material
                                  ).state
                                : ''
                            "
                            hint="Tipo de artefactos de herir o aferrar"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-divider light></v-divider>
                        </v-col>
                        <v-col cols="3" sm="12" md="3">
                          <v-text-field
                            readonly
                            :value="
                              barco.type_fad_state != null
                                ? barco.type_fad_state
                                : concepto.items_select_type_fad.find(
                                    (x) => x.abbr == barco.type_fad
                                  ) != undefined
                                ? concepto.items_select_type_fad.find(
                                    (x) => x.abbr == barco.type_fad
                                  ).state
                                : ''
                            "
                            hint="Tipo de FAD utilizados"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="12" md="3">
                          <v-text-field
                            readonly
                            :value="barco.fad_amount"
                            hint="Cantidad de FAD utilizados"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="12" md="6">
                          <v-text-field
                            readonly
                            :value="barco.fad_components"
                            hint="Componentes del FAD"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-container>
          <!--small>*indicates required field</small-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <!-- <v-btn color="indigo darken-4 white--text" dark @click="saveCompany">Crear</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {concepto, consultar} from "../services/variablesconcepto";

export default {
  props: ["modal", "dataModal", "title"],
  data: () => ({
    concepto: {},
    consecutive: "",
    lugar: "",
    items_select_authorized_arts: [
      { state: "1. SURROUNDING NETS – Red de Cerco", abbr: "UNO" },
      { state: "2. SEINE NETS – Redes de tiro", abbr: "DOS" },
      { state: "3. TRAWLS – Redes de arrastre", abbr: "TRES" },
      { state: "4. DREDGES – RASTRAS", abbr: "CUATRO" },
      { state: "5. LIFT NETS – Redes izadas", abbr: "CINCO" },
      { state: "6. FALLING GEAR – Redes de caida", abbr: "SEIS" },
      {
        state: "7. GILLNETS AND ENTANGLING NETS- Red agallera y de enmalle",
        abbr: "SIETE",
      },
      { state: "8. TRAPS - Trampas", abbr: "OCHO" },
      { state: "9. HOOKS AND LINES- Líneas y anzuelos", abbr: "NUEVE" },
      {
        state: "10. GRAPPLING AND WOUNDING – artes de aferrar o herir",
        abbr: "DIEZ",
      },
      {
        state: "11. HARVESTING MACHINES - Máquinas de recolección",
        abbr: "ONCE",
      },
      {
        state: "20. MISCELLANEOUS GEAR - artes diversos - polivalente",
        abbr: "VEINTE",
      },
      {
        state: "25. RECREATIONAL FISHING GEAR – artes de uso recreativo",
        abbr: "VEINTICINCO",
      },
      { state: "6. FALLING GEAR – Redes de caida", abbr: "SEIS" },
      {
        state: "99. Unknown - Desconocido",
        abbr: "NOVENTAYNUEVE",
      },
      { state: "8. TRAPS - Trampas", abbr: "OCHO" },
    ],
    array_e: [
      {
        id: 1,
        nombre: "",
        pivot: {
          description: "",
        },
      },
    ],
  }),
  computed: {
    permission() {
      if (this.dataModal.current_permit) {
        return this.dataModal.current_permit;
      } else return [];
    },
  },
  methods: {
    generatePDF() {},
  },
  async created() {
    this.concepto = await consultar.fetch();
    this.items_select_authorized_arts = concepto.items_select_authorized_arts.map(x => {
      return {
        state : x.nombre,
        abbr : x.codigo,
      }
    });
  },
};
</script>
