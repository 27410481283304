<template>
  <div> 
    <ToggleNavigation :items="items" :type="type"></ToggleNavigation>
    <v-snackbar v-model="snackbar" top="top" color="primary">
      Este permisionario ya tienen una solicitud a la espera de ser resuelta
      <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <v-card class="mx-auto">
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5,10,15]
        }"
        :headers="headers"
        :items="filtrado.data"
        :search="searchCompany"
        class="elevation-1"
        :server-items-length="filtrado.total"
        v-on:update:options="onPaginate"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title
              >Permisos de Pesca
              {{ type == "art" ? "Artesanal" : "Industrial" }}</v-toolbar-title
            >

            <ComponentCompanyModal
              v-on:handleClose="
                dialogSee = false;
                dataParent = [];
              "
              :modal="dialogSee"
              :dataModal="dataParent"
              v-if="dialogSee"
              title="GEN"
            ></ComponentCompanyModal>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="searchCompany"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              v-debounce:200ms="search"
            ></v-text-field>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="indigo darken-4 ml-4 white--text"
                  dark
                  small
                  class="mb-2"
                  @click="newReq"
                  v-on="on"
                  v-if="type == 'art' 
                  ? $can('gestionarProcesosArtesanales')
                  : $can('gestionarProcesosIndustriales') "
                >
                  <v-icon left small>fa-plus</v-icon>
                  Nuevo Permiso
                </v-btn>
                <v-btn
                  color="gray darken-4 ml-4 white--text"
                  small
                  dark
                  class="mb-2"
                  v-if=" 
                    type == 'art' 
                    ? $can('verProcedimientosArtesanales') 
                    : $can('verProcedimientosIndustriales')"
                  @click="ViewPeticiones"
                >
                  <v-icon small class="mr-2">assignment</v-icon>
                  Procedimientos
                </v-btn>

                <export-excel
                  :fetch.sync="exportCompanies"
                  :before-generate="startDownload"
                  :before-finish="finishDownload"
                  :fields="jsonFields"
                  worksheet="Permisionarios"
                  name="Permisionarios.xls"
                  style="ml-4 margin-bottom:10px"
                >
                  <v-btn
                    v-if=" type == 'art'
                    ? $can('exportarExcelArtesanales')
                    : $can('exportarExcelIndustriales')
                    "
                    color="green darken-4 ml-4 white--text"
                    small
                    dark
                    class="mb-2"
                  >
                    <v-icon small class="mr-2">fa fa-file-excel</v-icon>
                    Exportar
                  </v-btn>
                </export-excel>
              </template>
            </v-dialog>

            <v-dialog
              eager
              v-model="dialogRad"
              scrollable
              persistent
              width="1200px"
            >
              <v-card>
                <v-card-text>
                  <v-container>
                    <ComponentRadicado ref="setDataRad"></ComponentRadicado>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogRad = false"
                        >Cerrar</v-btn
                      >
                    </v-card-actions>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-toolbar>

          <v-menu v-model="showMenu">
            <template v-slot:activator="{ on }">
              <v-btn class="ml-4" v-on="on">
                {{ nameFilterFishery }}
                <v-icon>filter_list</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="setFilterFishery()"
                >
                  <v-list-item-title>Todos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="type in concepto.items_select_types_of_fishing"
                :key="type.codigo"
                :value="type"
                style="cursor: pointer"
              >
                <v-list-item-content
                  @click="setFilterFishery(type)"
                >
                  <v-list-item-title> {{ type.nombre }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="setFilterFishery({nombre: 'Otros', codigo: 'CINCO'})"
                >
                  <v-list-item-title>Otros</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu v-model="showMenuVigency">
            <template v-slot:activator="{ on }">
              <v-btn class="ml-4" v-on="on">
                {{ nameFilterVigency }}
                <v-icon>filter_list</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="setFilterVigency()"
                >
                  <v-list-item-title>Todos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="type in concepto.items_vigency_permi"
                :key="type.codigo"
                style="cursor: pointer"
              >
                <v-list-item-content
                  @click="setFilterVigency(type)"
                >
                  <v-list-item-title> {{ type.nombre }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
         <template v-slot:item.name="{ item }">
          {{item.name | uppercase}}
        </template>

        <template v-slot:item.tipos="{ item }">
          <span v-for="(item_, index) in pesqueria(item.current_permit)" :key="index">
            {{ item_.pivot.description }}.
          </span>
        </template>
        <template v-slot:item.current_permit="{ item }">
          <v-chip
            small
            :color="getPermitColor(item.current_permit)"
            :title="getPermitStatus(item.current_permit)"
            dark
            >{{ getPermitLabel(item.current_permit) }}
          </v-chip>
        </template>
        <template v-slot:item.action="{ item }">
          <v-menu left bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>

              <v-list-item @click="seeItem(item)" v-if=" 
                type == 'art' 
                ? $can('verDetalleDePermisionariosArtesanales') 
                : $can('verDetalleDePermisionariosIndustriales')">
                <v-list-item-title>
                  <v-icon small class="mr-2">remove_red_eye</v-icon>
                  Información
                </v-list-item-title>
              </v-list-item>
              
              <template  v-if=" 
                type == 'art' 
                ? $can('gestionarProcesosArtesanales') 
                : $can('gestionarProcesosIndustriales')">
                <v-list-item
                  v-if="getPermitStatus(item.current_permit) == 'Activo'"
                  @click="Peticion('p', item, 'CT')"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2" small>mdi-timetable</v-icon>
                    Prorroga
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="getPermitStatus(item.current_permit) == 'Activo'"
                  @click="Peticion('v', item, 'CT')"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2" small>file_copy</v-icon>
                    Vinculacion
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="Peticion('d', item, 'CT')"
                  v-if="getPermitStatus(item.current_permit) == 'Activo'"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2" small>block</v-icon>
                    Desvinculacion
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="getPermitStatus(item.current_permit) == 'Activo'"
                  @click="Peticion('c', item, 'CT')"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2" small>fa fa-times</v-icon>
                    Cancelacion
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="getPermitStatus(item.current_permit) != 'Activo'"
                  @click="Peticion('r', item, 'CT')"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2" small>check</v-icon>
                    Reactivación
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { concepto } from "../services/variablesconcepto";
import ComponentRadicado from "../components/ComponentRadicado";
import ComponentCompanyModal from "../components/ComponentCompanyModal";
import ToggleNavigation from "../components/ToggleNavigation";
import companyTypes from "../enums/companyTypes";
import companyFields from "../utils/companyFields";
import { navegacion } from "../services/exportNavigation";
import { getCompanyUrl, findRequestCompanyUrl, getHeader } from "../config";
import axios from "axios";
const DEFAULT_PER_PAGE = 10;
export default {
  props: ["type"],
  components: {
    ComponentRadicado,
    ComponentCompanyModal,
    ToggleNavigation,
  },
  data: () => ({
    items: [],
    filtrado: [],
    filterVigency: null,
    filterFishery: null,
    concepto: concepto,
    dialogSee: false,
    showMenu: false,
    showMenuVigency: false,
    nameFilterVigency: "Estado del Permiso",
    nameFilterFishery: "Tipos de Pesqueria",
    filters: [],
    dataParent: [],
    dialog: false,
    dialogRad: false,
    searchCompany: "",
    jsonFields: companyFields,
    headers: [
      { text: "Permisionario", value: "name" },
      {
        text: "NIT",
        align: "left",
        sortable: false,
        value: "nit_company",
      },
      { text: "No. de Embarcaciones ", align: "center", value: "boats_count" },
      { text: "Tipo de Pesquería", value: "tipos", sortable: false },
      { text: "Vigencia", value: "current_permit",  sortable: true  },
      { text: "Acciones", value: "action" ,  sortable: false },
    ],
    currentItemsPerPage: DEFAULT_PER_PAGE,
    snackbar: false,
  }),

  computed: {
    ...mapGetters([
      "allCompanies",
    ]),
  },
  watch: {
    type: async function (valueType) {
      const companyType = this.getCompanyType(valueType);
      this.filterCompany(companyType);
    },
  },

  methods: {
    getPermitColor(currentPermit){
      const status = currentPermit?.status;
      return {
        active: 'green',
        expired: 'red',
        alert: 'orange'
      }[status] || 'red'
    },
    getPermitStatus(currentPermit){
      const status = currentPermit?.status;
      return {
        active: 'Activo',
        expired: 'Vencido',
        canceled: 'Cancelado',
        alert: 'Por Vencer'
      }[status] || 'Sin Permiso'
    },
    search() {
     this.$store.commit("LOADER", undefined);
      //this.$store.commit("START_LOADER");
      const companyType = this.getCompanyType();
      this.filterCompany(companyType);
    },
    setFilterFishery(type = null){
      this.nameFilterFishery = type?.nombre || 'Tipos de Pesqueria';
      this.filterFishery = type?.codigo;
      const companyType = this.getCompanyType();
      this.filterCompany(companyType);
    },
    setFilterVigency(type = null) {
     this.nameFilterVigency = type?.nombre || 'Estado del Permiso';
     this.filterVigency = type?.codigo;
     const companyType = this.getCompanyType();
     this.filterCompany(companyType);
    },
    getCompanyType(type = null){
      return {
        art: companyTypes.ARTESANAL,
        ind: companyTypes.INDUSTRIAL, 
      }[type || this.type]
    },
    async filterCompany(companyType){
      this.filtrado = await this.getCompanies(companyType, {
       page: 1, itemsPerPage: this.currentItemsPerPage,
       ...this.getCurrentFilter()
     })
    },
    getCurrentFilter(){
      return {
        ...this.filterVigency && {vigency: this.filterVigency},
        ...this.filterFishery && {fishery: this.filterFishery},
        ...this.searchCompany && {search: this.searchCompany}
      }
    },
    onPaginate: async function(pagination) {
      const { sortBy, sortDesc, itemsPerPage, page } = pagination;
      this.currentItemsPerPage = itemsPerPage;
      this.filtrado = await this.getCompanies(this.getCompanyType(), {
        page, 
        itemsPerPage,
        ...this.getCurrentFilter(),
        ...sortBy[0] && {
              [`${sortBy[0]}`]: sortDesc[0]
        }
      });
    },
    async getCompanies(type, options) {
        await this.$store.dispatch("getCompanies", {type, options});
        return this.allCompanies;
    },
    pesqueria(currentPermit) {
      if (currentPermit) {
        return currentPermit.type_fishery;
      } else {
        return [];
      }
    },
    getPermitLabel(currentPermit) {
      if (currentPermit) {
        return currentPermit.effective_inactivation_date;
      } else {
        return "Sin Permiso";
      }
    },
   async exportCompanies(){
      const companyType = this.getCompanyType();
      return (await this.getCompanies(companyType, {
       page: 1, itemsPerPage: this.currentItemsPerPage,
       ...this.getCurrentFilter(), 
       excel: true
     }))?.data
    },
    startDownload() {
      this.currentItemsPerPage = this.filtrado.total;
      this.$store.commit("LOADER", true);
      //this.$store.commit("START_LOADER");
    },
    finishDownload() {
      //this.$store.commit("LOADER", false);
      this.$store.commit("STOP_LOADER");
      this.currentItemsPerPage = DEFAULT_PER_PAGE;
    },
    async seeItem(item) {
      this.dataParent = (await axios.get(`${getCompanyUrl}/${item.nit}`, {
          headers: getHeader(),
        }))?.data.data
      this.dialogSee = true;
    },
    Peticion: async function (action, item, title) {
      const requestCompany  = (await axios.get(`${findRequestCompanyUrl}/${item.nit}`, {
          headers: getHeader(),
        }))?.data.data
      if (requestCompany?.current_request) {
        this.dialogRad = false;
        this.snackbar = true;
      } else {
        this.dialogRad = true;
        this.$refs.setDataRad.setData(action, requestCompany, title, this.type);
      }
    },
    ViewPeticiones() {
      this.$router.push({
        path: `/requests-companies-${this.type}`,
      });
    },
    newReq() {
      this.dialogRad = true;
      this.$refs.setDataRad.setData("UNO", {}, "CT", this.type);
    },
  },

  created() {
    this.items = navegacion.items_navegation;
  }
};
</script>

<style lang="scss" scoped></style>
