export default {
  Permisionario: "name",
  NIT: "nit",
  "Dígito de Verificación": "cod_nit",
  "Representante Legal": "legal_representative",
  "Tipo de Documento": "id_type",
  "N° de Identificación": "identification_number",
  Direccion: "adress",
  Telefono: "phone",
  "E-mail": "email",
  "Permiso - Número de Autorización": {
    field: "current_permit",
    callback: (permission) => {
      if (permission) {
        return permission.authorization_number;
      }
    },
  },
  "Permiso - Fecha Notificación": {
    field: "current_permit",
    callback: (permission) => {
      if (permission) {
        return permission.date_of_notification;
      }
    },
  },
  "Permiso - Expedido Por": {
    field: "current_permit",
    callback: (permission) => {
      if (permission) {
        return permission.expedido_por?.nombre;
      }
    },
  },
  "Permiso - Titular de la Autorización": {
    field: "current_permit",
    callback: (permission) => {
      if (permission) {
        return permission.titular_autorizacion != null
          ? permission.titular_autorizacion.nombre
          : "";
      }
    },
  },
  "Permiso - Fecha inicio autorización": {
    field: "current_permit",
    callback: (value) => {
      if (value) {
        return value.effective_activation_date;
      }
    },
  },
  "Permiso - Fecha fin autorización": {
    field: "current_permit",
    callback: (value) => {
      if (value) {
        return value.effective_inactivation_date;
      }
    },
  },
  "Permiso - Zona Autorizada": {
    field: "current_permit",
    callback: (permission) => {
      if (permission) {
        return permission.zona_autorizada != null
          ? permission.zona_autorizada.nombre
          : "";
      }
    },
  },
  "Permiso - Puerto autorizado": {
    field: "current_permit",
    callback: (permission) => {
      if (permission) {
        return permission.puerto_desembarque_artesanal != null
          ? permission.puerto_desembarque_artesanal.nombre
          : "";
      }
    },
  },
  "Permiso - Tipo de pesquera": {
    field: "current_permit",
    callback: (permission) => {
      var types = "";
      let i = 1;
      if (permission) {
        permission.type_fishery.map((x) => {
          types += `${x.pivot.description}`;
          if (i < permission.type_fishery.length) {
            types += `, `;
          }
          i++;
        });
      }
      return `${types}`;
    },
  },
  "Permiso - Especies Autorizadas": {
    field: "current_permit",
    callback: (permission) => {
      var especies = "";
      let i = 1;
      if (permission) {
        permission.especies_autorizadas.map((x) => {
          especies += `${x.nombre}`;
          if (i < permission.especies_autorizadas.length) {
            especies += `, `;
          }
          i++;
        });
      }
      return `${especies}`;
    },
  },
  "Permiso - Artes de Pesca": {
    field: "current_permit",
    callback: (permission) => {
      var artes = "";
      let i = 1;
      if (permission) {
        permission.fishing_arts.map((x) => {
          artes += `${x.pivot.description}`;
          if (i < permission.fishing_arts.length) {
            artes += `, `;
          }
          i++;
        });
      }
      return `${artes}`;
    },
  },
  Embarcaciones: {
    field: "boats",
    callback: (value) => {
      var boats = "";
      if (value[0] != undefined) {
        var i = 1;
        value.map((element) => {
          var patent = "Sin patente vigente";
          if (element.date_expiration_patent != null)
            patent = element.date_expiration_patent;
          boats += `${element.boat_name}(MATRICULA : ${element.registration_number} - PATENTE: ${patent})`;
          if (i < value.length) {
            boats += " | ";
          }
          i++;
        });
        return `${boats}`;
      }
    },
  },
};
